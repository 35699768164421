<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      // Start holidays setup
      HrHolidays: [],
      HrDepartments: [],
      holiday: {},
      allSelected: false,
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchHrHolidays: null,
      HrHolidaysSearchMode: false,
      searchModel: "",
      state: state,
      // End holidays setup

      // Start holidays departments setup
      HrHolidayDepartments: [],
      departmentsPage: 1,
      departmentsLimit: 20,
      departmentsTotalPages: 0,
      searchHrHolidayDepartments: null,
      HrHolidayDepartmentsSearchMode: false,
      searchDepartmentsModel: "",
      // End holidays departments setup
    };
  },
  methods: {
    selectAll() {
      this.allSelected = true;
      this.HrDepartments.forEach((department) => (department.checked = true));
    },
    unSelectAll() {
      this.allSelected = false;
      this.HrDepartments.forEach((department) => (department.checked = false));
    },
    // Start holidays mehtods
    addHrHolidays() {
      this.holiday.departments = [];
      this.HrDepartments.forEach((department) => {
        if (department.checked) {
          this.holiday.departments.push({ hr_department_id: department.id });
        }
      });
      this.http.post("hr-holidays", this.holiday).then(() => {
        this.get(this.page);
      });
    },
    editHrHolidays(app) {
      var data = {
        title: "popups.hr_holidays",
        inputs: [
          { model: "name", type: "text", label: "name", value: app.name },
          {
            model: "start_date",
            type: "text",
            label: "start_date",
            value: app.start_date,
          },
          {
            model: "end_date",
            type: "text",
            label: "end_date",
            value: app.end_date,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("hr-holidays", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },

    search() {
      this.HrHolidaysSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("hr-holidays/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.HrHolidays = res.data;
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.HrHolidaysSearchMode = false;
      this.get(this.page);
    },
    getHrHolidays() {
      this.http.get("hr-holidays").then((res) => {
        this.HrHolidays = res.data;
      });
    },
    deleteHrHolidays(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("hr-holidays", app.id).then(() => {
            this.get(this.page);
          });
        }
      });
    },
    get(page) {
      console.log(page);
      this.http
        .post("hr-holidays/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.HrHolidays = res.data;
          this.getHrHolidayDepartments();
        });
    },
    // End holidays mehtods

    // Start holidays departments mehtods
    addHrHolidayDepartments() {
      if (this.HrDepartments.length <= 0) {
        this.http.get("hr-departments").then((resp) => {
          this.HrDepartments = resp.data;
          var data = {
            title: "popups.add",
            inputs: [
              {
                model: "hr_hoilday_id",
                type: "select",
                options: (() => {
                  return this.HrHolidays.map((data) => {
                    return { value: data.id, label: data.name };
                  });
                })(),
                label: "Departments",
              },
              {
                model: "hr_department_id",
                type: "select",
                options: (() => {
                  return this.HrDepartments.map((data) => {
                    return { value: data.id, label: data.name };
                  });
                })(),
                label: "Departments",
              },
            ],
            buttons: [
              {
                text: "popups.add",
                closer: true,
                color: "primary",
                handler: (obj) => {
                  this.http.post("hr-holiday-departments", obj).then(() => {
                    this.getDepartmentsPagination(this.departmentsPage, false);
                  });
                },
              },
              {
                text: "popups.cancel",
                closer: true,
                color: "danger",
                handler: () => {
                  console.log("Like Clicked");
                },
              },
            ],
          };
          this.popup.modal(data);
        });
        return;
      }
      var data = {
        title: "popups.add",
        inputs: [
          {
            model: "hr_hoilday_id",
            type: "select",
            options: (() => {
              return this.HrHolidays.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "Departments",
          },
          {
            model: "hr_department_id",
            type: "select",
            options: (() => {
              return this.HrDepartments.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "Departments",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("hr-holiday-departments", obj).then(() => {
                this.getDepartmentsPagination(this.departmentsPage, false);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editHrHolidayDepartments(app) {
      var data = {
        title: "popups.hr_holiday_departments",
        inputs: [
          {
            model: "hr_hoilday_id",
            type: "text",
            label: "hr_hoilday_id",
            value: app.hr_hoilday_id,
          },
          {
            model: "hr_department_id",
            type: "text",
            label: "hr_department_id",
            value: app.hr_department_id,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("hr-holiday-departments", app.id, obj).then(() => {
                this.getDepartmentsPagination(this.departmentsPage, false);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    searchDepartments() {
      this.HrHolidayDepartmentsSearchMode = true;
      this.departmentsTotalPages = 0;
      this.http
        .post("hr-holiday-departments/search", {
          search: this.searchDepartmentsModel,
          limit: this.departmentsLimit,
          page: this.departmentsPage,
          col: "name",
        })
        .then((res) => {
          this.HrHolidayDepartments = res.data;
        });
    },
    cancelDepartmentsSearchMode() {
      this.searchDepartmentsModel = "";
      this.HrHolidayDepartmentsSearchMode = false;
      this.getDepartmentsPagination(this.page, false);
    },
    getHrHolidayDepartments() {
      this.http.get("hr-departments").then((res) => {
        this.HrDepartments = res.data;
        this.HrDepartments.forEach((department) => {
          department.checked = false;
        });
      });
    },
    deleteHrHolidayDepartments(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("hr-holiday-departments", app.id).then(() => {
            this.getDepartmentsPagination(this.page, false);
          });
        }
      });
    },
    getDepartmentsPagination(page, tabClick) {
      if (tabClick && this.HrHolidayDepartments.length > 0) return;
      this.http
        .post("hr-holiday-departments/pagination", {
          limit: this.departmentsLimit,
          page: page,
        })
        .then((res) => {
          this.departmentsPage = page;
          this.departmentsTotalPages = Math.ceil(res.tot / this.limit);
          this.HrHolidayDepartments = res.data;
        });
    },
    // End holidays departments mehtods
  },
  created() {
    this.get(1);
  },
};
</script>

<template>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.hr.subItems.holidays')"
    />
    <!-- Start holidays tab -->
    <b-tabs
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
    >
      <b-tab active>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("hr_holidays.title") }}
          </span>
        </template>
        <div class="row">
          <div class="col-6">
            <div class="search-box chat-search-box w-50">
              <div class="position-relative">
                <input
                  @keyup="search()"
                  type="text"
                  class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                  :placeholder="$t('popups.search')"
                  v-model="searchModel"
                  style="background-color: #2a3042 !important"
                />
                <i class="bx bx-search-alt search-icon text-light"></i>
              </div>
            </div>
          </div>
          <div class="col-6" style="display: flex; justify-content: flex-end">
            <button
              class="btn-close text-light"
              v-if="HrHolidaysSearchMode"
              @click="cancelappsearchMode()"
            ></button>
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              class="btn btn-light float-end mb-4"
            >
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>
          </div>
        </div>

        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("hr_holidays.id") }}</th>
              <th scope="col">{{ $t("hr_holidays.name") }}</th>
              <th scope="col">{{ $t("hr_holidays.start_date") }}</th>
              <th scope="col">{{ $t("hr_holidays.end_date") }}</th>
              <th scope="col">{{ $t("hr_holidays.created") }}</th>
              <th scope="col">{{ $t("hr_holidays.updated") }}</th>
              <th scope="col">{{ $t("hr_holidays.operations") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="app in HrHolidays" :key="app" class="text-center">
              <td>{{ app?.id }}</td>
              <td>{{ app?.name }}</td>
              <td>{{ app?.start_date }}</td>
              <td>{{ app?.end_date }}</td>
              <td>{{ app.created.split("T")[0] }}</td>
              <td>{{ app.updated.split("T")[0] }}</td>

              <td class="d-flex">
                <a
                  class="btn btn-primary mx-1"
                  @click="editHrHolidays(app)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.edit") }}</a
                >
                <a
                  class="btn btn-danger"
                  href="javascript: void(0);"
                  role="button"
                  @click="deleteHrHolidays(app)"
                  >{{ $t("popups.delete") }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <!-- end  table -->
        <!--Start Add Modal-->
        <div
          class="modal fade"
          id="addModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <form @submit.prevent="addHrHolidays()">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ $t("popups.add") }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style="margin: 0"
                  ></button>
                </div>

                <div class="modal-body">
                  <div class="form-group">
                    <label for="name">{{ $t("hr_holidays.name") }}</label>
                    <input
                      v-model="holiday.name"
                      id="holiday_name"
                      required
                      class="form-control"
                      type="text"
                      :placeholder="$t('hr_holidays.name')"
                    />
                  </div>
                  <div class="form-group">
                    <label for="name">{{ $t("hr_holidays.start_date") }}</label>
                    <input
                      type="date"
                      id="start_date"
                      v-model="holiday.start_date"
                      required
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label for="name">{{ $t("hr_holidays.end_date") }}</label>
                    <input
                      type="date"
                      id="end_date"
                      v-model="holiday.end_date"
                      required
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label for="name">{{
                      $t("hr_holidays.departments")
                    }}</label>
                    <br />
                    <div
                      class="form-check form-check-inline"
                      style="
                        border-bottom: 1px solid #ccc;
                        margin: 5px;
                        width: 100%;
                        background: #fafafa;
                      "
                    >
                      <label v-if="!allSelected" for="name">select all</label>

                      <input
                        v-if="!allSelected"
                        type="checkbox"
                        class="form-check-input"
                        @change="selectAll($event)"
                        name=""
                        id=""
                      />

                      <label for="name" v-if="allSelected">un select all</label>

                      <input
                        v-if="allSelected"
                        type="checkbox"
                        checked
                        class="form-check-input"
                        @change="unSelectAll($event)"
                        name=""
                        id=""
                      />
                    </div>
                    <div
                      class="form-check"
                      v-for="department in HrDepartments"
                      :key="department"
                    >
                      <input
                        type="checkbox"
                        class="form-check-input"
                        v-model="department.checked"
                        name=""
                        id=""
                      />
                      <label for="name">{{ department.name }}</label>
                    </div>
                  </div>
                </div>

                <div
                  class="modal-footer"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                >
                  <button type="submit" class="btn btn-primary">
                    {{ $t("popups.add") }}
                  </button>
                  <button
                    data-bs-dismiss="modal"
                    type="button"
                    class="btn btn-danger"
                  >
                    {{ $t("popups.cancel") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!--End Add Modal-->

        <div v-if="tot_pages >= 20">
          <!--   Apps  Pagination     -->
          <ul class="pagination pagination-rounded justify-content-center mb-2">
            <li class="page-item" :class="{ disabled: page == 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>
            <li
              class="page-item"
              :class="{ active: p == page }"
              v-for="p in tot_pages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <li class="page-item" :class="{ disabled: page == tot_pages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
        <!--end-->
      </b-tab>
      <!-- End holidays tab -->

      <!-- Start holidays departments tab -->
      <b-tab @click="getDepartmentsPagination(1, true)">
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("hr_holiday_departments.title") }}
          </span>
        </template>
        <div class="row">
          <div class="col-6">
            <div class="search-box chat-search-box w-50">
              <div class="position-relative">
                <input
                  @keyup="search()"
                  type="text"
                  class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                  :placeholder="$t('popups.search')"
                  v-model="searchModel"
                  style="background-color: #2a3042 !important"
                />
                <i class="bx bx-search-alt search-icon text-light"></i>
              </div>
            </div>
          </div>
          <div class="col-6" style="display: flex; justify-content: flex-end">
            <button
              class="btn-close text-light"
              v-if="HrHolidayDepartmentsSearchMode"
              @click="cancelappsearchMode()"
            ></button>
            <button
              type="button"
              @click="addHrHolidayDepartments()"
              class="btn btn-light float-end mb-4"
            >
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>
          </div>
        </div>

        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("hr_holiday_departments.id") }}</th>
              <th scope="col">
                {{ $t("hr_holiday_departments.hr_hoilday_id") }}
              </th>
              <th scope="col">
                {{ $t("hr_holiday_departments.hr_department_id") }}
              </th>
              <th scope="col">{{ $t("hr_holiday_departments.created") }}</th>
              <th scope="col">{{ $t("hr_holiday_departments.updated") }}</th>
              <th scope="col">{{ $t("hr_holiday_departments.operations") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="app in HrHolidayDepartments"
              :key="app"
              class="text-center"
            >
              <td>{{ app?.id }}</td>
              <td>{{ app?.hr_hoilday?.name }}</td>
              <td>{{ app?.hr_department?.name }}</td>
              <td>{{ app.created.split("T")[0] }}</td>
              <td>{{ app.updated.split("T")[0] }}</td>

              <td class="d-flex">
                <a
                  class="btn btn-primary mx-1"
                  @click="editHrHolidayDepartments(app)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.edit") }}</a
                >
                <a
                  class="btn btn-danger"
                  href="javascript: void(0);"
                  role="button"
                  @click="deleteHrHolidayDepartments(app)"
                  >{{ $t("popups.delete") }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <!-- end  table -->
        <!-- Button trigger modal -->

        <div v-if="departmentsTotalPages >= 20">
          <!--   Apps  Pagination     -->
          <ul class="pagination pagination-rounded justify-content-center mb-2">
            <li class="page-item" :class="{ disabled: departmentsPage == 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(departmentsPage - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>
            <li
              class="page-item"
              :class="{ active: p == departmentsPage }"
              v-for="p in departmentsTotalPages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <li
              class="page-item"
              :class="{ disabled: departmentsPage == departmentsTotalPages }"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(departmentsPage + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
        <!--end-->
      </b-tab>
      <!-- End holidays departments tab -->
    </b-tabs>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
